import { render, staticRenderFns } from "./wallet.vue?vue&type=template&id=1cf4a4e3&scoped=true"
import script from "./wallet.vue?vue&type=script&lang=js"
export * from "./wallet.vue?vue&type=script&lang=js"
import style0 from "./wallet.vue?vue&type=style&index=0&id=1cf4a4e3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cf4a4e3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,PublicCreditCardRial: require('/app/components/public/credit-card/Rial.vue').default,PublicCreditCardTether: require('/app/components/public/credit-card/Tether.vue').default,PublicTabBtns: require('/app/components/public/TabBtns.vue').default,CoreSelectSimple: require('/app/components/core/select/Simple.vue').default,PublicTetherIcon: require('/app/components/public/tether/Icon.vue').default,PublicLirIcon: require('/app/components/public/lir/Icon.vue').default})
