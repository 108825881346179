
export default {
  name: "login",
  layout: "auth",
  data() {
    return {
      loginType: "by-password",
      user: {
        type: null,
        user: null,
        hash: null,
      },
      password: null,
      code: null,
      formFlags: {
        passwordHasErr: true,
        codeHasErr: true,
      },

      codeTimer: {
        active: false,
        minute: 0,
        second: 0,
      },

      device: {
        ip: "",
        os: "WINDOWS",
        screen: "DESKTOP",
        browser: "CHROME",
      },
    };
  },
  async asyncData(context) {
    if (process.server) {
      const req = context.req;
      const ip =
        req.headers["x-forwarded-for"] || // From proxy headers, can be spoofed if you don't have a proxy in front of your app, so drop it if your app is naked.
        req.connection.remoteAddress ||
        req.socket.remoteAddress || // socket is an alias to connection, just delete this line
        req.connection.socket.remoteAddress || // no idea where this might be a thing, just delete this line
        "0.0.0.0";
      // probably add a default at the end here, although there shouldn't be a case when req.connection.remoteAddress is unset.
      return { ip };
    }
  },
  watch: {
    "codeTimer.second"() {
      if (this.codeTimer.second > 0) {
        this.codeTimer.active = true;
        // trigger the timer for next watch
        setTimeout(() => {
          this.codeTimer.second--;
        }, 1000);
      } else {
        if (this.codeTimer.minute > 0) {
          this.codeTimer.active = true;
          this.codeTimer.minute--;
          this.codeTimer.second = 59;
        } else {
          this.codeTimer.active = false;
        }
      }
    },
  },
  beforeMount() {
    if (localStorage.getItem("auth-user")) {
      let user = JSON.parse(localStorage.getItem("auth-user"));
      if (user.type && user.user) {
        this.user.type = user.type;
        this.user.user = user.user;
        this.user.hash = user.hash;

        if (user.time && user.time - Math.floor(Date.now() / 1000) > 0) {
          let expireTimeInSecond = user.time - Math.floor(Date.now() / 1000);
          this.codeTimer.active = true;
          this.codeTimer.minute = Math.floor(expireTimeInSecond / 60);
          this.codeTimer.second = expireTimeInSecond % 60;
        }
      } else {
        this.$router.push("/auth");
      }
    } else {
      this.$router.push("/auth");
    }
  },
  mounted() {
    this.device.ip = this.ip || "0.0.0.0";
    this.checkDevice();
  },
  methods: {
    async changeLoginType(type) {
      this.loginType = type;
      if (!this.codeTimer.active && type === "by-code") {
        await this.sendVerificationCode();
      }
    },
    async loginByPassword() {
      if (this.password) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          const payload = {
            user: this.user.user,
            password: this.password,
            device: this.device,
          };
          await this.$auth.loginWith("authLogin", { data: payload });
          localStorage.removeItem("auth-user");
        } catch (err) {
          this.$store.dispatch(
            "messagePopup/error",
            "کاربری با اطلاعات وارد شده یافت نشد."
          );
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
    async loginByCode(code = this.code) {
      if (code) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          const payload = {
            user: this.user.user,
            code: code,
            hash: this.user.hash,
            device: this.device,
          };
          await this.$auth.loginWith("authLogin", { data: payload });
          localStorage.removeItem("auth-user");
        } catch (err) {
          this.$store.dispatch(
            "messagePopup/error",
            "کد تایید وارد شده اشتباه است."
          );
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
    async sendVerificationCode() {
      if (!this.codeTimer.active) {
        try {
          let payload = {
            user: this.user.user,
          };
          let resp = await this.$axios.$post("/v1/auth/send-code", payload);
          this.user.hash = resp.hash;

          localStorage.setItem(
            "auth-user",
            JSON.stringify({
              type: this.user.type,
              user: this.user.user,
              hash: resp.hash,
              time: Math.floor(Date.now() / 1000) + 120,
            })
          );

          this.codeTimer.active = true;
          this.codeTimer.minute = 1;
          this.codeTimer.second = 59;
        } catch (err) {
          this.$store.dispatch(
            "messagePopup/error",
            "ارسال کد تایید با مشکل مواجه شد."
          );
        }
      }
    },
    checkDevice() {
      if (this.$device.isDesktop) this.device.screen = "DESKTOP";
      else if (this.$device.isMobile) this.device.screen = "MOBILE";
      else if (this.$device.isTablet) this.device.screen = "TABLET";
      else if (this.$device.isMobileOrTablet) this.device.screen = "TABLET";
      else if (this.$device.isDesktopOrTablet) this.device.screen = "DESKTOP";
      else this.device.screen = "OTHER";

      if (this.$device.isIos) this.device.os = "IOS";
      else if (this.$device.isWindows) this.device.os = "WINDOWS";
      else if (this.$device.isWindows) this.device.os = "WINDOWS";
      else if (this.$device.isMacOS) this.device.os = "MACOS";
      else this.device.os = "OTHER";

      if (this.$device.isFirefox) this.device.browser = "FIREFOX";
      else if (this.$device.isEdge) this.device.browser = "EDGE";
      else if (this.$device.isChrome) this.device.browser = "CHROME";
      else if (this.$device.isSafari) this.device.browser = "SAFARI";
      else if (this.$device.isSamsung) this.device.browser = "SAMSUNG";
      else if (this.$device.isCrawler) this.device.browser = "CRAWLER";
      else this.device.browser = "OTHER";
    },
  },
};
