
export default {
  name: "profile",
  layout: "dashboard",
  middleware: 'auth',
  data() {
    return {
      formFlags: {
        hasError: true,
      },
      form: {
        name: this.$auth.user?.name || "",
        fatherName: this.$auth.user?.profile?.fatherName || "",
        internationalCode: this.$auth.user?.profile?.internationalCode || "",
        serialNumber: this.$auth.user?.profile?.serialNumber || "",
        birthday: this.$auth.user?.profile?.birthday || "",
        mobile: this.$auth.user?.mobile || "",
        email: this.$auth.user?.email || "",
        phone: this.$auth.user?.address?.phone || '',
        city: this.$auth.user?.address?.city || '',
        postalCode: this.$auth.user?.address?.postalCode || '',
        address: this.$auth.user?.address?.address || '',
      },
      isKYC: false
    }
  },
  computed: {
    emptyField() {
      return !!(this.form.name && this.form.fatherName && this.form.internationalCode && this.form.birthday && this.form.mobile && this.form.email && this.form.city && this.form.address);
      // return !!(this.form.name && this.form.fatherName && this.form.internationalCode && this.form.birthday && this.form.mobile && this.form.email && this.form.city);
    }
  },
  methods: {
    async updateUser() {
      if (!this.formFlags.hasError && this.emptyField) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          let resp = await this.$axios.$put('/v1/user', this.form);
          await this.$auth.fetchUser();
          this.$store.dispatch("usdtConfig/set", resp.price);
          this.$store.dispatch("lirConfig/set", resp.lirPrice);
          this.$store.dispatch("messagePopup/success", "اطلاعات شما با موفقیت ویرایش شد.");
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "فرم ارسالی دارای اشکال می باشد.");
      }
    }
  },
  beforeMount() {
    this.isKYC = this.$auth.user?.isKYC
  }
}
