
// import {PhotoCapture} from 'vue-media-recorder'

export default {
  name: "upload-card",
  layout: "dashboard",
  middleware: "auth",
  // components: {
  //   PhotoCapture
  // },
  data() {
    return {
      testImage: null,
      tabButtons: [
        { id: 0, name: "گالری" },
        { id: 1, name: "دوربین" },
      ],
      imageSrc: null,
      takeImage: false,
      selectedTab: 0,
      formData: {
        image: null,
      },
      formFlags: {
        loading: false,
        disable: true,
      },
      videoDataUrl: "",
      videoDataBlob: "",
      recordStart: false,

      acceptRulesFlag: false,
      acceptRulesTimer: {
        active: false,
        minute: 0,
        second: 0,
      },
      isUploaded: false,
      imageSize: 0,
    };
  },
  watch: {
    videoDataUrl(newVal) {
      this.formFlags.disable = !newVal;
    },
    imageSrc(newVal) {
      this.takeImage = true;
    },
  },
  methods: {
    setImage(image) {
      this.imageSrc = image.url;
      this.imageSize = image.size;
      this.takeImage = true;
      this.isUploaded = true;
    },
    async uploadCard() {
      if (this.imageSrc && this.takeImage) {
        if (this.isUploaded) {
          if (this.imageSize <= 1000000) {
            this.uploadFile();
          } else {
            this.$store.dispatch(
              "messagePopup/error",
              "حجم فایل بیشتر از 1MB می باشد."
            );
          }
        } else {
          this.uploadFile();
        }
      } else {
        this.$store.dispatch(
          "messagePopup/error",
          "لطفا یک عکس بارگذاری نمایید."
        );
      }
    },
    async uploadFile() {
      this.$store.dispatch("overlay/toggleOverlay");
      try {
        await this.$axios.$post("/v1/document/upload-image", {
          image: this.imageSrc,
        });
        await this.$auth.fetchUser();
        this.$store.dispatch(
          "messagePopup/success",
          "عکس با موفقیت بارگذاری شد."
        );
        this.$router.push("/dashboard/kyc/upload-video");
      } catch (err) {
        this.$store.dispatch("messagePopup/error", err.response.data.message);
      } finally {
        this.$store.dispatch("overlay/toggleOverlay");
      }
    },
    Recorded(item) {
      this.videoDataUrl = item.url;
      this.videoDataBlob = item.blob;
      this.recordStart = true;
    },
    refreshVideo() {
      this.videoDataUrl = "";
      this.videoDataBlob = "";
      this.recordStart = false;
      // window.location.reload();
    },
    prevStep() {
      this.$router.push("/dashboard/kyc/accept-rules");
    },
  },

  beforeMount() {
    if (
      this.$auth.user.ImageKYCStatus === "NONE" ||
      this.$auth.user.ImageKYCStatus === "CANCELED"
    ) {
      this.acceptRulesTimer.second = 20;
    } else {
      this.$router.push("/dashboard/kyc/upload-video");
    }
  },
};
