
import Button from '../../components/core/Button.vue';
export default {
  components: { Button },
  name: "referral",
  layout: "dashboard",
  middleware: 'auth',
  data() {
    return {
      user: null,
      kycCount: 0,
      tradeCount: 0
    };
  },

  async fetch() {
    try {
      this.$store.dispatch("overlay/toggleOverlay");
      this.user = await this.$axios.$get("/v1/invite");
      this.user.inviters.forEach(inviter => {
        if (inviter.isKYC)
          this.kycCount++;
        this.tradeCount += inviter.userOrder.completed
      });
    } catch (err) {
      this.$store.dispatch("messagePopup/error", err.response.data.message);
    } finally {
      this.$store.dispatch("overlay/toggleOverlay");
    }
  },

  computed: {
    inviteUrl() {
      return `https://tether-ex.com/auth?invite=${this.$auth.user?.inviteCode}`
    },
  },

  methods: {
    copy(val) {
      navigator.clipboard.writeText(val);
    }
  }
}
