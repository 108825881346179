
export default {
  name: "accept-rules",
  layout: "dashboard",
  middleware: 'auth',
  beforeMount() {
    if (this.$auth.user.isKYC)
      this.$router.push('/dashboard');
    if (this.$auth.user && this.$auth.user.isMobileOwner) {
      if (this.$auth.user.ImageKYCStatus === 'VERIFY') {
        this.$router.push('/dashboard/kyc/upload-card');
      }
    }
  },
  methods: {
    submitRules() {
      this.$router.push('/dashboard/kyc/upload-card');
    }
  }
}
