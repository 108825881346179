
import Pagination from 'vue-pagination-2';

export default {
  name: "wallet",
  layout: "dashboard",
  middleware: 'auth',
  components: {
    Pagination,
  },
  data() {
    return {
      tabButtons: [
        // {id: 0, name: "همه"},
        { id: 0, name: "برداشت" },
        { id: 1, name: "واریز" },
      ],
      selectedType: 0,
      selectedCoin: null,
      walletHistory: [],
      paginate: {
        total: 150,
        limit: 5,
        page: 1,
      }
    }
  },
  computed: {
    coinTypes() {
      if (this.selectedType === 0)
        return [
          { id: 'ALL', label: 'همه' },
          { id: 'IRT', icon: 'irt', label: 'تومان' },
          { id: 'USDT', icon: 'usdt', label: 'تتر' },
          { id: 'LIR', icon: 'trl', label: 'لیر' },
        ]
      else
        return [
          { id: 'IRT', icon: 'irt', label: 'تومان' },
          { id: 'USDT', icon: 'usdt', label: 'تتر' },
        ]
    },
    typesIndex() {
      let type = ['withdraw', 'deposit'];
      return type[this.selectedType];
    },
    filteredHistory() {
      let histories = this.walletHistory;
      let newHistories = [];
      histories.forEach(history => {

        let date = new Date(history.createdAt).toLocaleDateString('fa-IR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        let dateTime = new Date(history.createdAt).toLocaleTimeString('fa-IR');

        newHistories.push({
          type: 'withdraw',
          coin: history.type,
          amount: history.amount,
          status: history.status,
          account: history.account,
          wallet: history.wallet,
          date: `${dateTime} - ${date}`,
        });
      });
      return newHistories;
    }
  },
  watch: {
    'paginate.page'(newVal) {
      if (this.typesIndex === 'withdraw') {
        this.getWithdrawals(newVal, this.paginate.limit, this.selectedCoin.id);
      } else if (this.typesIndex === 'deposit') {
        this.getDeposits(newVal, this.paginate.limit, this.selectedCoin.id);
      }
    },
    selectedCoin(newVal) {
      if (this.typesIndex === 'withdraw') {
        this.paginate.page = 1;
        this.getWithdrawals(this.paginate.page, this.paginate.limit, newVal.id);
      } else if (this.typesIndex === 'deposit') {
        this.getDeposits(this.paginate.page, this.paginate.limit, newVal.id);
      }
    },
    selectedType(newVal) {
      if (this.typesIndex === 'withdraw') {
        this.paginate.page = 1;
        this.getWithdrawals(this.paginate.page, this.paginate.limit, this.selectedCoin.id);
      } else if (this.typesIndex === 'deposit') {
        this.getDeposits(this.paginate.page, this.paginate.limit, this.selectedCoin.id);
      }
    }
  },
  methods: {
    async showTransactionDetail(order) {
      let title;
      let amount;
      let account;
      let trackingCode;

      if (order.coin === 'IRT') {
        title = order.type === 'withdraw' ? 'جزئیات برداشت تومان' : 'جزئیات واریز تومان';
        amount = { label: 'مبلغ', value: `${(order.amount).toLocaleString('fa-IR')} تومان` };
        let banks;
        if (this.$store.state.bankAccounts.banksList.length > 0) {
          banks = this.$store.state.bankAccounts.banksList;
        } else {
          await this.$store.dispatch("bankAccounts/bankList");
          banks = this.$store.state.bankAccounts.banksList;
        }
        let bankImg = banks.filter(bank => bank.prefixShebaNumber === (order.account.sheba).substring(4, 7));
        account = {
          label: 'حساب بانکی',
          value: order.account.sheba,
          image: `${this.$config.cdnUrl}/${bankImg[0].image}`
        };
        trackingCode = { label: 'کد پیگیری', value: '1543242' };
      } else if (order.coin === 'USDT') {
        title = order.type === 'withdraw' ? 'جزئیات برداشت تتر' : 'جزئیات واریز تتر';
        amount = { label: 'مقدار', value: `${(order.amount).toLocaleString('fa-IR')} تتر` };
        let accountVal = order.wallet.address.substr(0, 6) + "......" + order.wallet.address.substr((order.wallet.address).length - 6, (order.wallet.address).length);
        account = { label: 'کیف پول', value: accountVal, valFontEn: true, };
        trackingCode = {
          label: 'هش تراکنش',
          value: 'tasdhjjkvxcuiewrh...',
          linkTo: 'https://google.com',
          valFontEn: true,
        };
      } else if (order.coin === 'LIR') {
        title = order.type === 'withdraw' ? 'جزئیات برداشت لیر' : 'جزئیات واریز لیر';
        amount = { label: 'مقدار', value: `${(order.amount).toLocaleString('fa-IR')} لیر` };
        let accountVal = order.wallet.address.substr(0, 6) + "......" + order.wallet.address.substr((order.wallet.address).length - 6, (order.wallet.address).length);
        account = { label: 'کیف پول', value: accountVal, valFontEn: true, };
        trackingCode = {
          label: 'هش تراکنش',
          value: 'tasdhjjkvxcuiewrh...',
          linkTo: 'https://google.com',
          valFontEn: true,
        };
      }

      let payload = {
        title: {
          icon: order.type,
          text: title,
        },
        body: {
          icon: (order.coin).toLowerCase(),
          status: order.status,
          irtPrice: 0,
          usdtPrice: 0,
          content: [
            amount,
            account,
            trackingCode,
            { label: 'زمان', value: order.date },
          ],
        }
      };
      this.$store.dispatch("dashboardModalFactor/setFactorDetails", payload);
      this.$store.dispatch("dashboardModalFactor/show");
    },
    async getWithdrawals(page = 1, limit = 5, type = 'ALL') {
      let url = `/v1/withdrawal?page=${page}&limit=${limit}&sort=createdAt&order=desc&query=`;
      if (type !== 'ALL') {
        url += `&type=${type}`;
      }
      this.$store.dispatch("overlay/toggleOverlay");
      try {
        let resp = await this.$axios.$get(url);
        this.walletHistory = resp.withdrawals;
        this.paginate.total = resp.total;
        this.$store.dispatch("usdtConfig/set", resp.price);
        this.$store.dispatch("lirConfig/set", resp.lirPrice);
      } catch (err) {
        this.$store.dispatch("messagePopup/error", err.response.data.message);
      } finally {
        this.$store.dispatch("overlay/toggleOverlay");
      }
    },

    async getDeposits(page = 1, limit = 5, type = 'ALL') {
      let url = `/v1/deposit?page=${page}&limit=${limit}&sort=createdAt&order=desc&query=`;
      if (type !== 'ALL') {
        url += `&type=${type}`;
      }
      this.$store.dispatch("overlay/toggleOverlay");
      try {
        let resp = await this.$axios.$get(url);
        this.walletHistory = resp.deposits;
        this.paginate.total = resp.total;
        this.$store.dispatch("usdtConfig/set", resp.price);
        this.$store.dispatch("lirConfig/set", resp.lirPrice);
      } catch (err) {
        this.$store.dispatch("messagePopup/error", err.response.data.message);
      } finally {
        this.$store.dispatch("overlay/toggleOverlay");
      }
    },
  },
  async fetch() {
    await this.getWithdrawals(this.paginate.page, this.paginate.limit);
  }
}
