
export default {
  name: "ContactUsPage",
  head() {
    return {
      title: "تماس با ما",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "تتر اکسچنج | راه های ارتباط با وب سایت.",
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "کارمزد تتر اکسچنج, کارمزد رایگان, کارمزد خرید, کارمزد فروش",
        },
        { hid: "logo", name: "image", content: "~/layouts/logos/default.svg" },
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "googlebot", content: "index,follow" },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          hreflang: "fa",
          href: "https://tether-ex.com/contact-us",
        },
      ],
    };
  },
};
