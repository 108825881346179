
export default {
  name: "BlogPage",

  head() {
    return {
      title: "بلاگ",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "بلاگ تتر اکسچنج مرجع اخبار، آموزش، آشنایی، معرفی و بررسی در زمینه بیت کوین، رمز ارزها، بلاک‌ چین و کیف پول ارز دیجیتال می باشد.",
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "تبادل نظر درباره تتر اکسچنج, دیدگاه های کاربران تتر اکسچنج",
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          hreflang: "fa",
          href: `https://tether-ex.com/blog?page=${this.$route.query.page || 1
            }`,
        },
      ],
    };
  },

  data() {
    return {
      slideGroup: 0,
      posts: [],
      selectedPosts: [],
      firstSelectedPost: null,
      marked: [],
      categories: [],
      limit: 10,
      page: parseInt(this.$route.query.page) || 1,
      query: "",
      sort: "createdAt",
      order: "desc",
      total: 0,
      loading: false,
      mostView: []
    };
  },

  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },

  watch: {
    page(newValue) {
      // this.$router.push({ path: "/blog", query: { page: newValue } });
      this.getData();
    },
  },

  async fetch() {
    try {
      this.$store.dispatch("overlay/toggleOverlay");
      this.loading = true;
      const response = await this.$axios.$get(
        `/v1/post?page=${this.page}&limit=${this.limit}&query=${this.query}&sort=${this.sort}&order=${this.order}`
      );
      this.posts = response.posts;
      this.firstSelectedPost = response.selected[0];
      response.selected.splice(0, 1);
      this.selectedPosts = response.selected;
      this.marked = response.marked;
      this.total = response.total;
      this.categories = response.categories;
      this.mostView = response.mostView
    } catch (error) {
      this.$store.dispatch("messagePopup/error", error.response.data.message);
    } finally {
      this.$store.dispatch("overlay/toggleOverlay");
      this.loading = false;
    }
  },

  methods: {
    getImagePath(image) {
      const base64ToArray = image?.split(";base64,");
      if (base64ToArray)
        if (base64ToArray.length > 1) return image;
        else return this.$config.cdnUrl + "/" + image;
    },
    async getData() {
      try {
        this.$store.dispatch("overlay/toggleOverlay");
        const response = await this.$axios.$get(
          `/v1/post?page=${this.page}&limit=${this.limit}&query=${this.query}&sort=${this.sort}&order=${this.order}`
        );
        console.log(response.posts)
        this.posts.push(...response.posts);
        console.log(this.posts)
      } catch (error) {
        this.$store.dispatch("messagePopup/error", error.response.data.message);
      } finally {
        this.$store.dispatch("overlay/toggleOverlay");
      }
    },

    goToRoute(slug) {
      this.$router.push(
        {
          path: `/blog/${slug}`,
          force: true,
        },
        () => {
          this.$router.app.refresh();
        }
      );
    },

    moreLoad() {
      if (this.total > this.posts.length) {
        this.page++;
      } else {
        this.$store.dispatch("messagePopup/error", 'داده ای برای نمایش موجود نیست!');
      }
    }
  },
};
