
export default {
  name: "callback",
  layout: "single",
  data () {
    return {
      status: '',
      code: '',
    }
  },
  async fetch () {
    this.status = this.$route.query.status;
    this.code = this.$route.query.code ? this.$route.query.code : null;
  }
}
