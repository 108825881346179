
export default {
  name: "index",
  layout: "auth",
  data() {
    return {
      form: {
        flags: {
          hasError: true,
        },
        data: {
          user: null,
        },
      },
    };
  },
  beforeMount() {
    localStorage.removeItem("auth-user");
    localStorage.removeItem("register-step");
  },
  methods: {
    async checkUser() {
      if (!this.form.flags.hasError) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          let payload = {
            user: this.form.data.user,
          };
          let resp = await this.$axios.$post("/v1/auth/check", payload);

          let user = {
            type: null,
            user: resp.user,
            hash: resp.hash,
          };
          // check user is that mobile or email
          if (/^09[0|1|2|3|9][0-9]{8}$/.test(resp.user)) {
            user.type = "mobile";
          } else if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(resp.user)
          ) {
            user.type = "email";
          }
          localStorage.setItem("auth-user", JSON.stringify(user));

          if (resp.isRegistered) {
            this.$router.push("/auth/login");
          } else {
            user.time = Math.floor(Date.now() / 1000) + 119;
            localStorage.setItem("auth-user", JSON.stringify(user));
            if (this.$route.query && this.$route.query.invite)
              this.$router.push(
                `/auth/register?invite=${this.$route.query.invite}`
              );
            else this.$router.push("/auth/register");
          }
        } catch (err) {
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
  },
};
