
// import fs from "fs";
// import {VideoCapture} from 'vue-media-recorder'

export default {
  name: "upload-video",
  layout: "dashboard",
  middleware: 'auth',
  /*components: {
    VideoCapture
  },*/
  data() {
    return {
      tabButtons: [
        { id: 0, name: 'گالری' },
        { id: 1, name: 'دوربین' },
      ],
      selectedTab: 0,
      formData: {
        image: null,
      },
      formFlags: {
        loading: false,
        disable: true,
      },
      videoFile: null,
      videoDataUrl: "",
      videoDataBlob: "",
      recordStart: false,
    }
  },
  watch: {
    videoDataUrl(newVal) {
      this.formFlags.disable = !newVal;
    },
  },
  methods: {
    async uploadVideo() {
      if (this.videoFile || this.videoDataBlob) {
        this.$store.dispatch("overlay/toggleOverlay");
        let formData = new FormData();
        if (this.selectedTab === 0) {
          formData.append("file", this.videoFile);
        } else if (this.selectedTab === 1) {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          formData.append("file", this.videoDataBlob, `${this.$auth.user.id}_${timestamp}.mp4`);
        }
        try {
          await this.$axios.$post('/v1/document/upload-video', formData);
          await this.$auth.fetchUser();
          this.$store.dispatch("messagePopup/success", "درخواست احراز هویت شما با موفقیت ثبت شد.");
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response?.data?.message);
          // this.$store.dispatch("messagePopup/error", formData);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "لطفا یک ویدیو جهت بارگذاری انتخاب نمایید.");
      }
    },
    Recorded(item) {
      this.videoDataUrl = item.url;
      this.videoDataBlob = item.blob;
      this.recordStart = true;
    },
    refreshVideo() {
      this.videoDataUrl = "";
      this.videoDataBlob = "";
      this.recordStart = false;
      // window.location.reload();
    },
    prevStep() {
      this.$router.push('/dashboard/a-kyc/upload-card');
    },
  },
  beforeMount() {
    if (this.$auth.user.VideoKYCStatus !== 'NONE' && this.$auth.user.VideoKYCStatus !== 'CANCELED') {
      this.$router.push('/dashboard');
    }
  },
}
