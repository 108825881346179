
export default {
  name: "register",
  layout: "auth",
  data() {
    return {
      referralCodeDisabled: false,
      registerStep: "step-1",
      user: {
        type: null,
        user: null,
        hash: null,
      },
      codeTimer: {
        active: false,
        minute: 0,
        second: 0,
      },
      code: null,
      acceptRules: null,
      hasErr: true,
      form: {
        user: "",
        name: "",
        email: "",
        mobile: "",
        password: "",
        referralCode: "",
      },
      device: {
        ip: "",
        os: "WINDOWS",
        screen: "DESKTOP",
        browser: "CHROME",
      },
    };
  },
  async asyncData(context) {
    if (process.server) {
      const req = context.req;
      const ip =
        req.headers["x-forwarded-for"] || // From proxy headers, can be spoofed if you don't have a proxy in front of your app, so drop it if your app is naked.
        req.connection.remoteAddress ||
        req.socket.remoteAddress || // socket is an alias to connection, just delete this line
        req.connection.socket.remoteAddress || // no idea where this might be a thing, just delete this line
        "0.0.0.0";
      // probably add a default at the end here, although there shouldn't be a case when req.connection.remoteAddress is unset.
      return { ip };
    }
  },
  watch: {
    "codeTimer.second"() {
      if (this.codeTimer.second > 0) {
        this.codeTimer.active = true;
        // trigger the timer for next watch
        setTimeout(() => {
          this.codeTimer.second--;
        }, 1000);
      } else {
        if (this.codeTimer.minute > 0) {
          this.codeTimer.active = true;
          this.codeTimer.minute--;
          this.codeTimer.second = 59;
        } else {
          this.codeTimer.active = false;
        }
      }
    },
  },
  beforeMount() {
    this.referralCodeDisabled = false;
    if (this.$route.query && this.$route.query.invite) {
      this.form.referralCode = this.$route.query.invite;
      this.referralCodeDisabled = true;
    }

    if (localStorage.getItem("auth-user")) {
      let user = JSON.parse(localStorage.getItem("auth-user"));
      if (user.type && user.user) {
        this.user.type = user.type;
        this.user.user = user.user;
        this.user.hash = user.hash;
        this.form.user = user.user;

        if (this.user.type === "mobile") {
          this.form.mobile = user.user;
        } else if (this.user.type === "email") {
          this.form.email = user.user;
        }

        if (user.time && user.time - Math.floor(Date.now() / 1000) > 0) {
          let expireTimeInSecond = user.time - Math.floor(Date.now() / 1000);
          this.codeTimer.active = true;
          this.codeTimer.minute = Math.floor(expireTimeInSecond / 60);
          this.codeTimer.second = expireTimeInSecond % 60;
        }

        if (
          localStorage.getItem("register-step") &&
          localStorage.getItem("register-step") === "2"
        ) {
          this.registerStep = "step-2";
        }
      } else {
        this.$router.push("/auth");
      }
    } else {
      this.$router.push("/auth");
    }
  },
  mounted() {
    this.device.ip = this.ip || "0.0.0.0";
    this.checkDevice();
  },
  methods: {
    async confirmCode(code = this.code) {
      if (code) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          let payload = {
            user: this.user.user,
            hash: this.user.hash,
            code: code,
          };
          let resp = await this.$axios.$post("/v1/auth/confirm", payload);
          this.form.user = this.user.user;
          localStorage.setItem("register-step", "2");
          this.registerStep = "step-2";
        } catch (err) {
          this.$store.dispatch(
            "messagePopup/error",
            "کد تایید وارد شده اشتباه است."
          );
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
    async register() {
      if (!this.hasErr) {
        if (this.acceptRules) {
          this.$store.dispatch("overlay/toggleOverlay");
          try {
            let payload = {
              user: this.form.user,
              name: this.form.name,
              email: this.form.email,
              mobile: this.form.mobile,
              password: this.form.password,
              referralCode: this.form.referralCode,
              device: this.device,
            };
            await this.$auth.loginWith("authRegister", {
              data: payload,
            });
          } catch (err) {
            if (err.response.data.message)
              this.$store.dispatch(
                "messagePopup/error",
                err.response?.data?.message
              );
            else
              this.$store.dispatch(
                "messagePopup/error",
                "مشکلی در روند ثبت نام به وجود آمده است. لطفا مجددا تلاش کنید."
              );
          } finally {
            this.$store.dispatch("overlay/toggleOverlay");
          }
        } else {
          this.$store.dispatch(
            "messagePopup/error",
            "پذیرش قوانین برای ثبت نام در تتر اکسچنج الزامی می باشد."
          );
        }
      }
    },
    async sendVerificationCode() {
      if (!this.codeTimer.active) {
        try {
          let payload = {
            user: this.user.user,
          };
          let resp = await this.$axios.$post("/v1/auth/send-code", payload);
          this.user.hash = resp.hash;

          localStorage.setItem(
            "auth-user",
            JSON.stringify({
              type: this.user.type,
              user: this.user.user,
              hash: resp.hash,
              time: Math.floor(Date.now() / 1000) + 119,
            })
          );

          this.codeTimer.active = true;
          this.codeTimer.minute = 1;
          this.codeTimer.second = 59;
        } catch (err) {
          this.$store.dispatch(
            "messagePopup/error",
            "ارسال کد تایید با مشکل مواجه شد."
          );
        }
      }
    },
    checkDevice() {
      if (this.$device.isDesktop) this.device.screen = "DESKTOP";
      else if (this.$device.isMobile) this.device.screen = "MOBILE";
      else if (this.$device.isTablet) this.device.screen = "TABLET";
      else if (this.$device.isMobileOrTablet) this.device.screen = "TABLET";
      else if (this.$device.isDesktopOrTablet) this.device.screen = "DESKTOP";
      else this.device.screen = "OTHER";

      if (this.$device.isIos) this.device.os = "IOS";
      else if (this.$device.isWindows) this.device.os = "WINDOWS";
      else if (this.$device.isWindows) this.device.os = "WINDOWS";
      else if (this.$device.isMacOS) this.device.os = "MACOS";
      else this.device.os = "OTHER";

      if (this.$device.isFirefox) this.device.browser = "FIREFOX";
      else if (this.$device.isEdge) this.device.browser = "EDGE";
      else if (this.$device.isChrome) this.device.browser = "CHROME";
      else if (this.$device.isSafari) this.device.browser = "SAFARI";
      else if (this.$device.isSamsung) this.device.browser = "SAMSUNG";
      else if (this.$device.isCrawler) this.device.browser = "CRAWLER";
      else this.device.browser = "OTHER";
    },
  },
};
