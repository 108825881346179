import { render, staticRenderFns } from "./about-us.vue?vue&type=template&id=7c6f06dc&scoped=true"
import script from "./about-us.vue?vue&type=script&lang=js"
export * from "./about-us.vue?vue&type=script&lang=js"
import style0 from "./about-us.vue?vue&type=style&index=0&id=7c6f06dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c6f06dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicRowList: require('/app/components/public/RowList.vue').default})
