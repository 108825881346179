
export default {
    name: "application",
    data() {
        return {
            tel: '',
            features: [
                {
                    icon: "like",
                    label: "دسترسی و استفاده آسان",
                    desc: "تجربه خرید و فروش تتر متفاوت در کنار قیمت منصفانه سرعت و امنیت"
                },
                {
                    icon: "megaphone",
                    label: "اطلاع زودتر از اخبار و رویدادها",
                    desc: "اپلیکیشن تتر اکسچنج شما را از اخبار و رویدادهای داغ بازار ارز دیجیتال تتر مطلع می کند."
                },
                {
                    icon: "charts",
                    label: "اطلاع لحظه ای از وضعیت بازار",
                    desc: "با نصب اپلیکیشن تتر اکسچنج هر زمان و هرجا که هستید یک لحظه هم از وضعیت بازار رمزارها غافل نمی شوید."
                },
                {
                    icon: "gift-box",
                    label: "جوایز متنوع",
                    desc: "هر ماه جوایز جذابی به طور ویژه برای کاربران اپلیکیشن داریم."
                },
                {
                    icon: "notification",
                    label: "اعلان های شخصی متناسب با تراکش های شما",
                    desc: "با توجه به پیشینه معاملات شما، از پیشنهادات زمان مناسب خرید و فروش تتر مطلع شوید."
                },
                {
                    icon: "wallet",
                    label: "بدون محدودیت با کیف پول ریالی",
                    desc: "انجام معاملات سنگین و سریع با استفاده از کیف پول ریالی در اپلیکیشن تتر اکسچنج"
                },
            ]
        }
    },

    methods: {
        async sendSMS() {
            try {
                this.$store.dispatch("toggleOverlay", true);
                // await this.$axios.$post('v1/user-application-sms/send', {
                //     mobile: this.tel
                // })
                this.$store.dispatch("toggleOverlay", false);
                this.$store.dispatch("message/messageShow", {
                    text: "لینک درخواست شما با موفقیت ارسال گردید.",
                    color: "success",
                });
            } catch (error) {
                this.$store.dispatch("toggleOverlay", false);
                this.$store.dispatch("message/messageShow", {
                    text: "درخواست شما قبلا ثبت شده است.",
                    color: "error",
                });
            } finally {
                this.tel = ''
            }
        }
    }
}
