import { render, staticRenderFns } from "./upload-card.vue?vue&type=template&id=cfd86f26&scoped=true"
import script from "./upload-card.vue?vue&type=script&lang=js"
export * from "./upload-card.vue?vue&type=script&lang=js"
import style0 from "./upload-card.vue?vue&type=style&index=0&id=cfd86f26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfd86f26",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreButton: require('/app/components/core/Button.vue').default,CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,PublicTabBtns: require('/app/components/public/TabBtns.vue').default,CoreInputFileField: require('/app/components/core/input/FileField.vue').default,PublicCapturePhoto: require('/app/components/public/CapturePhoto.vue').default})
