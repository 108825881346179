
export default {
  name: "security",
  layout: "dashboard",
  middleware: 'auth',
  data() {
    return {
      totp: {
        secret: null,
        qrUrl: null,
      },
      TOTPCode: null,
    }
  },
  methods: {
    async getSecretCode() {
      this.$store.dispatch("overlay/toggleOverlay");
      try {
        let resp = await this.$axios.$get('/v1/user/totp-code');
        this.totp.secret = resp.user.TOTPCode;
        this.totp.qrUrl = `otpauth://totp/Tether-ex(${this.$auth.user.mobile})?secret=${resp.user.TOTPCode}`;
      } catch (err) {
        this.$store.dispatch("messagePopup/error", err.response.data.message);
      } finally {
        this.$store.dispatch("overlay/toggleOverlay");
      }
    },
    async enableTotp() {
      if (this.TOTPCode) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          let resp = await this.$axios.$post('/v1/user/enable-totp', {token: this.TOTPCode});
          this.TOTPCode = '';
          await this.$auth.fetchUser();
          await this.$store.dispatch("messagePopup/success", "تایید دو مرحله با موفقیت برای حساب کاربری شما فعال گردید.");
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
    async disableTotp() {
      if (this.TOTPCode) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          let resp = await this.$axios.post('/v1/user/check-totp', {token: this.TOTPCode});
          if (resp.status === 200) {
            await this.$axios.$get('/v1/user/disable-totp');
            this.TOTPCode = '';
            await this.$auth.fetchUser();
            await this.$store.dispatch("messagePopup/success", "تایید دو مرحله ای برای حساب کاربری شما غیرفعال گردید.");
          } else {
            this.$store.dispatch("messagePopup/error", "مشکلی در روند غیرفعالسازی تایید دو مرحله ای به وجود آمده است. لطفا بعدا مجددا امتحان نمایید.");
          }
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
  },
  async fetch() {

  }
}
