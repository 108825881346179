
import Banners from "@/data/dashboard-banners.json";

export default {
  name: "index",
  layout: "dashboard",
  middleware: "auth",
  data() {
    return {
      banners: Banners,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          filler: {
            propagate: true,
          },
          tooltip: {
            rtl: true,
            titleFont: {
              family: "iransans",
            },
            bodyFont: {
              family: "iransans",
            },
            /*callbacks: {
              label: function (context) {
                if (context.dataset.label === 'موجودی') {
                  return `موجودی : ${(context.parsed.y * 1000000).toLocaleString('fa-IR')} تومان`;
                } else {
                  return `${context.dataset.label} : ${context.parsed.y}`;
                }
              }
            }*/
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              color: "rgba(27, 152, 245, .05)",
              drawBorder: false,
              drawOnChartArea: true,
              drawTicks: true,
            },
            ticks: {
              display: true,
              padding: 2,
              color: "rgba(23, 23, 23, .25)",
            },
          },
          y: {
            grid: {
              display: false,
              color: "rgba(23, 23, 23, .025)",
              drawBorder: false,
              drawOnChartArea: true,
              drawTicks: false,
            },
            ticks: {
              display: false,
              padding: 10,
              color: "rgba(23, 23, 23, .1)",
            },
          },
        },
      },
      chartData: {
        labels: [0],
        datasets: [
          {
            label: "حجم معاملات",
            fill: true,
            borderWidth: 2,
            borderColor: "#009E89",
            backgroundColor: "rgb(0, 158, 137, .025)",
            pointBorderWidth: 0,
            pointBackgroundColor: "transparent",
            data: [0],
            tension: 0.4,
          },
          {
            label: "حجم معاملات فروش",
            fill: true,
            borderWidth: 2,
            borderColor: "#D85140",
            backgroundColor: "rgba(216, 81, 64, .025)",
            pointBorderWidth: 0,
            pointBackgroundColor: "transparent",
            data: [0].reverse(),
            tension: 0.4,
          },
        ],
      },
      orders: [],
      devices: [],
      userOrder: {
        buy: 0,
        buyCompleted: 0,
        completed: 0,
        sell: 0,
        sellCompleted: 0,
        total: 0,
        totalBuyVolume: 0,
        totalSellVolume: 0,
        totalVolume: 0,
      },
    };
  },
  async fetch() {
    try {
      this.$store.dispatch("overlay/toggleOverlay");
      let resp = await this.$axios.$get("/v1/dashboard");
      this.orders = resp.orders;
      this.devices = resp.devices;
      this.userOrder = resp.userOrder;
      console.log(resp)
      await this.$store.dispatch("usdtConfig/set", resp.price);
      await this.$store.dispatch("lirConfig/set", resp.lirPrice);
    } catch (err) {
      this.$store.dispatch("messagePopup/error", err.response.data.message);
    } finally {
      this.$store.dispatch("overlay/toggleOverlay");
    }
  },
};
