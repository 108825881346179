
import Pagination from 'vue-pagination-2';

export default {
  name: "crypto-wallets",
  layout: "dashboard",
  middleware: 'auth',
  components: {
    Pagination,
  },
  data() {
    return {
      formData: {
        selectedNetwork: null,
        title: '',
        address: '',
        hasErr: true,
      },

      networks: [
        { icon: "/layouts/icons/networks/trx.svg", label: "TRC20" },
        // {icon: "/layouts/icons/networks/eth.svg", label: "ERC20"},
        // {icon: "/layouts/icons/networks/trx.svg", label: "ERC20"},
      ],

      // wallets list
      wallets: [],
      paginate: {
        total: 150,
        limit: 5,
        page: 1,
      }
    }
  },
  watch: {
    'paginate.page'(newVal) {
      this.getWalletsList(newVal, this.paginate.limit);
    }
  },
  methods: {
    setPage(page) {
      this.paginate.page = page;
    },
    async getWalletsList(page = 1, limit = 5) {
      await this.$store.dispatch("cryptoWallets/get", { page, limit });
      this.wallets = this.$store.state.cryptoWallets.wallets;
      this.paginate.total = this.$store.state.cryptoWallets.total;
    },
    async addNewWallet() {
      if (!this.formData.hasErr) {
        await this.$store.dispatch("cryptoWallets/add", { title: this.formData.title, address: this.formData.address });
        this.wallets = this.$store.state.cryptoWallets.wallets;
        this.formData.title = '';
        this.formData.address = '';
      } else {
        this.$store.dispatch("messagePopup/error", "فرم دارای اشکال می باشد.");
      }
    },
    copy(val) {
      navigator.clipboard.writeText(val);
    }
  },
  async fetch() {
    await this.getWalletsList(this.paginate.page, this.paginate.limit);
  }
}
