
import Pagination from "vue-pagination-2";

export default {
  name: "bank-accounts",
  layout: "dashboard",
  middleware: 'auth',
  components: {
    Pagination,
  },
  data() {
    return {
      newAccForm: {
        card: ''
      },
      newLirAccForm: {
        name: '',
        iban: 'TR'
      },
      accounts: [],
      lirAccounts: [],
      bankList: [],
      paginate: {
        total: 150,
        limit: 5,
        page: 1,
      },
      lirPaginate: {
        total: 150,
        limit: 5,
        page: 1,
      }
    }
  },
  computed: {
    newCardPreview() {
      let card = this.newAccForm.card;
      let bank = this.bankList.filter(bank => bank.prefixCardNumber === card.substring(0, 6));
      if (bank[0]) {
        return {
          name: bank[0].title,
          image: bank[0].image,
        };
      } else {
        return {
          name: 'بانک نامشخص',
          image: '',
        }
      }
    },
  },
  methods: {
    async getBankList() {
      if (this.$store.state.bankAccounts.banksList.length === 0) {
        await this.$store.dispatch("bankAccounts/bankList");
        this.bankList = this.$store.state.bankAccounts.banksList;
      } else {
        this.bankList = this.$store.state.bankAccounts.banksList;
      }
    },
    async getBankAccounts() {
      if (this.$store.state.bankAccounts.accounts.length === 0) {
        await this.$store.dispatch("bankAccounts/getAccounts");
        this.accounts = this.$store.getters["bankAccounts/combine"];
      } else {
        this.accounts = this.$store.getters["bankAccounts/combine"];
      }
    },
    async getLirAccounts() {
      try {
        let resp = await this.$axios.$get('/v1/lirAccount?page=1&limit=10&sort=createdAt&order=asc&query=');
        this.lirAccounts = resp.lirAccounts;
        this.lirPaginate.total = resp.total;
        this.$store.dispatch("usdtConfig/set", resp.price);
        this.$store.dispatch("lirConfig/set", resp.lirPrice);
      } catch (err) {
        console.log(err);
      }
    },
    async addNewAccound() {
      if (this.newAccForm.card.length === 16) {
        await this.$store.dispatch("bankAccounts/add", { card: this.newAccForm.card });
        this.accounts = this.$store.getters["bankAccounts/combine"];
        this.newAccForm.card = '';
      } else {
        this.$store.dispatch("messagePopup/error", "شماره کارت وارد شده نامعتبر می باشد.");
      }
    },
    async addNewLirAccound() {
      const iban = this.newLirAccForm.iban.slice(2)
      if (iban.length === 24) {
        try {
          this.$store.dispatch("overlay/toggleOverlay");
          let resp = await this.$axios.$post('/v1/lirAccount', {
            iban: iban,
            name: this.newLirAccForm.name,
          });
          console.log(resp)
          this.newLirAccForm.iban == "";
          this.newLirAccForm.name == "";
          this.$store.dispatch("messagePopup/success", "حساب بانکی جدید با موفقیت اضافه شد.");
          this.getLirAccounts()
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "شماره iban وارد شده نامعتبر می باشد.");
      }
    },
    copy(val) {
      let card = val.split(" ");
      card = card.join('');
      navigator.clipboard.writeText(`شماره کارت : ${card}`);
    }
  },
  async fetch() {
    await this.getBankList();
    await this.getBankAccounts();
    await this.getLirAccounts();
  }
}
