import { render, staticRenderFns } from "./profile.vue?vue&type=template&id=9b7d9226&scoped=true"
import script from "./profile.vue?vue&type=script&lang=js"
export * from "./profile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b7d9226",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,CoreInputTextField: require('/app/components/core/input/TextField.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
