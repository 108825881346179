
import Pagination from "vue-pagination-2";

export default {
  name: "dashboard-orders",
  layout: "dashboard",
  middleware: "auth",
  components: {
    Pagination,
  },
  data() {
    return {
      tabButtons: [
        { id: 0, name: "همه" },
        { id: 1, name: "خرید" },
        { id: 2, name: "فروش" },
      ],
      selectedType: 0,
      statusFilters: [
        { id: "ALL", label: "همه" },
        { id: "COMPLETED", label: "تکمیل شده" },
        { id: "SUBMITTED", label: "در حال بررسی" },
        { id: "CANCELED", label: "رد شده" },
      ],
      selectedStatus: null,
      orders: [],
      paginate: {
        total: 0,
        limit: 5,
        page: 1,
      },
    };
  },
  computed: {
    filteredOrders() {
      let orders = this.orders;
      let newOrders = [];
      orders.forEach((order) => {
        let date = new Date(order.createdAt).toLocaleDateString("fa-IR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        let dateTime = new Date(order.createdAt).toLocaleTimeString("fa-IR");
        newOrders.push({
          type: order.type,
          status: order.status,
          amount: order.amount,
          price: order.price,
          usdt: order.usdt,
          fee: order.fee,
          trackingCode: order.trackingCode,
          date: `${dateTime} - ${date}`,
        });
      });
      return newOrders;
    },
    typesIndex() {
      let type = ["ALL", "BUY", "SELL"];
      return type[this.selectedType];
    },
  },
  watch: {
    "paginate.page"(newVal) {
      this.getOrders(newVal, this.paginate.limit);
    },
    selectedStatus(newVal) {
      this.paginate.page = 1;
      this.getOrders(
        this.paginate.page,
        this.paginate.limit,
        "",
        this.typesIndex,
        newVal.id
      );
    },
    selectedType(newVal) {
      this.paginate.page = 1;
      this.getOrders(
        this.paginate.page,
        this.paginate.limit,
        "",
        this.typesIndex,
        this.selectedStatus.id
      );
    },
  },
  methods: {
    setPage(page) {
      this.paginate.page = page;
    },
    async getOrders(page, limit, search = "", type = "ALL", status = "ALL") {
      let url = `/v1/order?page=${page}&limit=${limit}&sort=createdAt&order=desc&query=${search}`;
      if (type !== "ALL") {
        url += `&type=${type}`;
      }
      if (status !== "ALL") {
        url += `&status=${status}`;
      }
      this.$store.dispatch("overlay/toggleOverlay");
      try {
        let resp = await this.$axios.$get(url);
        this.orders = resp.orders;
        this.paginate.total = resp.total;
        this.$store.dispatch("usdtConfig/set", resp.price);
        this.$store.dispatch("lirConfig/set", resp.lirPrice);
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch("overlay/toggleOverlay");
      }
    },
    showTransactionDetail(order, showText = false) {
      let title;
      let receive;
      let fee;
      let text;

      if (order.type === "BUY") {
        title = "جزئیات خرید تتر";
        fee = {
          label: "کارمزد تتر اکسچنج",
          value: `${order.fee.toLocaleString("fa-IR")} تتر`,
        };
        receive = {
          label: "مقدار دریافتی",
          value: `${(order.amount - order.fee).toLocaleString("fa-IR")} تتر`,
        };
        text = "خرید تتر با موفقیت انجام شد.";
      } else if (order.type === "SELL") {
        title = "جزئیات فروش تتر";
        fee = {
          label: "کارمزد تتر اکسچنج",
          value: `${order.fee.toLocaleString("fa-IR")} تومان`,
        };
        receive = {
          label: "مبلغ دریافتی",
          value: `${(order.price - order.fee).toLocaleString("fa-IR")} تتر`,
        };
        text = "فروش تتر با موفقیت انجام شد.";
      }

      let payload = {
        confirmText: showText ? text : "",
        title: {
          icon: "transfer",
          text: title,
        },
        body: {
          icon: "usdt",
          status: order.status,
          irtPrice: order.price,
          usdtPrice: order.amount,
          content: [
            {
              label: "نرخ لحظه",
              value: `${order.usdt.toLocaleString("fa-IR")} تومان`,
            },
            fee,
            receive,
            { label: "کد پیگیری", value: order.trackingCode },
            { label: "زمان", value: order.date },
          ],
        },
      };
      this.$store.dispatch("dashboardModalFactor/setFactorDetails", payload);
      this.$store.dispatch("dashboardModalFactor/show");
    },
  },
  async fetch() {
    await this.getOrders(
      this.paginate.page,
      this.paginate.limit,
      "",
      "ALL",
      "ALL"
    );
    if (this.$route.params.showOrder) {
      this.showTransactionDetail(this.$route.params.showOrder, true);
    }
  },
};
